import axios from '@/axios';

async function getDataMahasiswaDaftarUlangByTahunAjaranRangeTanggal(tahunAjaran, tanggalMulai, tanggalSelesai) {
    let endpoint = `old/daftar-ulang?tahun_ajaran=${tahunAjaran}&tanggal_pembayaran_awal=${tanggalMulai}&tanggal_pembayaran_akhir=${tanggalSelesai}&limit=all`;
    let resDataMahasiswa = await axios.get(endpoint);
    return resDataMahasiswa;
}

function getEndpontExportDaftarUlangHarianByTahunAjaranRangeTanggal(tahunAjaran, tanggalMulai, tanggalSelesai, token) {
    let link = `${axios.defaults.baseURL}/old/daftar-ulang?tahun_ajaran=${tahunAjaran}&tanggal_pembayaran_awal=${tanggalMulai}&tanggal_pembayaran_akhir=${tanggalSelesai}&export=1&token=${token}&limit=all`
    return link
}

export default {
    getDataMahasiswaDaftarUlangByTahunAjaranRangeTanggal,
    getEndpontExportDaftarUlangHarianByTahunAjaranRangeTanggal,
}