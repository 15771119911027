<template>
  <div class="base-smart-table">
    <div v-if="!reqFindDone">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div v-else>
      <label>Search:</label>
      <input
        class="form-control"
        v-model="filters.dataPeserta.value"
        :disabled="dataPeserta.length == 0"
      />
      <br />
      <v-table
        ref="table-mhs"
        class="table-hover"
        :data="dataPeserta"
        :currentPage.sync="currentPage"
        :pageSize="pageSize"
        :filters="filters"
        selectedClass="table-info"
        @totalPagesChanged="totalPages = $event"
      >
        <thead slot="head">
          <v-th v-for="it in columns" :key="it.key" :sortKey="it.key">{{
            it.value
          }}</v-th>
        </thead>
        <tbody slot="body" slot-scope="{ displayData }">
          <template v-if="dataPeserta.length > 0">
            <v-tr v-for="row in displayData" :key="row.id" :row="row">
              <td
                v-for="item in columns"
                :key="item.key + ' X ' + row[item.key]"
              >
                {{ row[item.key] }}
              </td>
            </v-tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="columns.length">Tidak ada Data</td>
            </tr>
          </template>
        </tbody>
      </v-table>
      <br />
      <smart-pagination
        :maxPageLinks="5"
        :currentPage.sync="currentPage"
        :totalPages="totalPages"
        :boundaryLinks="boundaryLinks"
      />
    </div>
  </div>
</template>

<script>
/**
 * Component yang digunakan untuk menampilkan data dalam bentuk tabel, serta bisa melakukan filter terhadap data.
 * @example @/docs/ExampleBaseSmartTable.md
 */
export default {
  name: "BaseSmartTable",
  props: {
    /**
     * Field yang ditampilkan pada table
     * @type {Array<Object>}
     */
    dataColumns: {
      type: Array,
      required: true,
    },
    /**
     * Data untuk setiap baris pada table
     * @type {Array<Object>}
     */
    dataRows: {
      type: Array,
      required: true,
    },
    /**
     * Jumlah Data yang ditampilkan per halaman
     */
    pageSize: {
      type: Number,
      default: 10,
    },
    /**
     * Menampilkan Page Navigation ke First Page dan Last Page
     */
    boundaryLinks: {
      type: Boolean,
      default: true,
    },
    /**
     * Function untuk melakukan filter terhadap table
     */
    smartTableFilter: {
      type: Function,
      required: true,
    },
    /**
     * Flag yang menandakan request data table selesai
     */
    reqFindDone: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      columns: this.dataColumns,
      dataPeserta: this.dataRows,
      filters: {
        dataPeserta: { value: "", custom: this.smartTableFilter },
      },
      currentPage: 1,
      totalPages: 0,
    };
  },
  watch: {
    reqFindDone: function (newVal, oldVal) {
      if (oldVal != newVal && newVal) {
        this.dataPeserta = this.dataRows;
      }
    },
    dataColumns: {
      handler(newVal) {
        this.columns = newVal;
      },
      deep: true,
    },
    dataRows: {
      handler(newVal) {
        this.dataPeserta = newVal;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.base-smart-table {
  table {
    display: block;
    width: 100%;
    overflow: auto;
    word-break: normal;
    word-break: keep-all;
    font-size: 11px;
  }

  table th {
    font-weight: bold;
  }

  table th,
  table td {
    padding: 0.5rem 1rem;
    border: 1px solid #e9ebec;
    word-break: break-word;
    white-space: pre;
  }

  table tr.section {
    font-size: 0.75rem;
    font-weight: bold;
  }
}
</style>