<template>
  <div class="form-group">
    <label v-if="namaLabelBrowse" for="select2">{{ namaLabelBrowse }}</label>
    <v-select
      :options="arrayOptions"
      v-model="localDataSelected"
      @input="onSelect"
      :disabled="Boolean(disabled)"
      :clearable="false"
    />
  </div>
</template>
<script>
/**
 * Component digunakan untuk membuat component Select2.
 * @example @/docs/ExampleBaseVueSelect.md
 */
export default {
  name: "BaseVueSelect",
  props: {
    /**
     * Nama Label pada Select2
     */
    namaLabelBrowse: {
      type: String,
      default: "",
    },
    /**
     * Data Pilihan yang tersedia
     * @type {Array<string>}
     */
    arrayOptions: {
      type: Array,
      required: true,
    },
    /**
     * Data yang terpilih
     */
    dataSelected: {
      type: String,
      required: true,
    },
    /**
     * Function yang tertrigger ketika on-change select2
     */
    onChangeFunction: {
      type: Function,
      default: () => {},
    },
    /**
     * Flag required input
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag enabled / disabled input
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDataSelected: this.dataSelected,
    };
  },
  computed: {
    requiredInput: function () {
      if (this.required) {
        return this.localDataSelected == "";
      }
      return false;
    },
  },
  watch: {
    dataSelected: {
      handler: async function (newVal) {
        this.localDataSelected = newVal;
      },
      deep: true,
    },
  },
  methods: {
    /**
     * Trigger ketika ada perubahan selected data
     * @public
     * @event onSelect
     * @param {-}
     */
    onSelect: async function () {
      /**
       * Update parent selected dataSelected.
       * @event update:dataSelected
       * @property {String} dataSelected - Selected data
       */
      this.$emit("update:dataSelected", this.localDataSelected);
      this.onChangeFunction();
    },
  },
};
</script>
