<template>
  <div class="laporan-daftar-ulang-harian">
    <CCard>
      <CCardHeader> Laporan Daftar Ulang Harian </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <BaseVueSelect
              nama-label-browse="Tahun Ajaran"
              :data-selected.sync="tahunAjaran"
              :array-options="arrayDataTahunAjaran"
              :disabled="false"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <div class="form-group">
              <label for="tanggal-daftar-ulang-mulai"
                >Dari Tanggal Pembayaran Daftar Ulang</label
              >
              <input
                id="tanggal-daftar-ulang-mulai"
                class="form-control"
                type="date"
                v-model="tanggalDaftarUlangMulai"
                required
              />
            </div>
          </CCol>
          <CCol sm="6">
            <div class="form-group">
              <label for="tanggal-daftar-ulang-selesai"
                >Sampai Tanggal Pembayaran Daftar Ulang</label
              >
              <input
                id="tanggal-daftar-ulang-selesai"
                class="form-control"
                type="date"
                v-model="tanggalDaftarUlangSelesai"
                required
              />
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="12" class="text-right mt-3">
            <b-button class="mr-2" variant="primary" @click="onClickFilter">
              <b-icon icon="filter" class="mr-2" /> Filter
            </b-button>
            <b-button variant="primary" @click="onClickExport">
              <b-icon icon="printer" class="mr-2" />
              Export
            </b-button>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard v-if="showLaporan">
      <CCardHeader>Laporan Daftar Ulang</CCardHeader>
      <CCardBody>
        <CRow class="mb-3">
          <CCol sm="6"> <b>Jumlah Pendaftar:</b> {{ jumlahPendaftar }} </CCol>
          <CCol sm="6"> <b>Total Pembayaran:</b> {{ totalPembayaran }} </CCol>
        </CRow>
        <BaseSmartTable
          :data-columns="headerTable"
          :data-rows="detailTable"
          :smart-table-filter="smartTableFilter"
          :req-find-done="reqTableDone"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import BaseVueSelect from "@/components/base/BaseVueSelect.vue";
import BaseSmartTable from "@/components/base/BaseSmartTable.vue";

import KonfigurasiApi from "@/api/KonfigurasiApi.js";
import DaftarUlangOldApi from "@/api/DaftarUlangOldApi.js";

export default {
  name: "LaporanDaftarUlangHarian",
  components: {
    BaseVueSelect,
    BaseSmartTable,
  },
  data() {
    return {
      arrayDataTahunAjaran: [],
      tahunAjaran: "",

      jumlahPendaftar: 0,
      totalPembayaran: 0,

      tanggalDaftarUlangMulai: "",
      tanggalDaftarUlangSelesai: "",
      headerTable: [
        { key: "tanggal", value: "Tanggal" },
        { key: "no_usm", value: "No USM" },
        { key: "nama", value: "Nama" },
        { key: "nim", value: "NIM" },
        { key: "dp3", value: "Biaya DP3" },
        { key: "mpt", value: "Biaya MPT" },
        { key: "martikulasi", value: "Biaya Martikulasi" },
        { key: "uang_kuliah", value: "Uang Kuliah" },
        { key: "potongan", value: "Potongan" },
        { key: "total", value: "Total" },
      ],
      detailTable: [],
      reqTableDone: false,

      showLaporan: false,
    };
  },
  async created() {
    this.tanggalDaftarUlangMulai = new Date().toISOString().substring(0, 10);
    this.tanggalDaftarUlangSelesai = new Date().toISOString().substring(0, 10);
    await this.getDataTahunAjaran();
    this.setDefaultTahunAjaran();
  },
  methods: {
    setDefaultTahunAjaran: function () {
      let jlhDataTahunAjaran = this.arrayDataTahunAjaran.length;
      if (jlhDataTahunAjaran > 0) {
        this.tahunAjaran = this.arrayDataTahunAjaran[jlhDataTahunAjaran - 1];
      }
    },
    getDataTahunAjaran: async function () {
      try {
        let jenjang = 1;
        let resTahunAjaran =
          await KonfigurasiApi.getAllKonfigurasiTahunAjaranByJenjang(jenjang);
        if (resTahunAjaran.status == 200) {
          let dataTahunAjaran = resTahunAjaran.data.data;
          let temp = dataTahunAjaran.map((it) => {
            return it.tahun_ajaran;
          });
          this.arrayDataTahunAjaran = [...temp];
        } else {
          let errorMessage = resTahunAjaran.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        return Promise.reject(err);
        // this.flash(err, "error");
      }
    },
    smartTableFilter: function (filterValue, row) {
      filterValue = filterValue.toLowerCase();
      return (
        row.tanggal.toLowerCase().includes(filterValue) ||
        row.no_usm.toLowerCase().includes(filterValue) ||
        row.nim.toLowerCase().includes(filterValue) ||
        row.nama.toLowerCase().includes(filterValue)
      );
    },
    onClickFilter: async function () {
      this.showLaporan = true;
      this.reqTableDone = false;
      this.detailTable = [];
      try {
        let responseDaftarMahasiswa =
          await DaftarUlangOldApi.getDataMahasiswaDaftarUlangByTahunAjaranRangeTanggal(
            this.tahunAjaran,
            this.tanggalDaftarUlangMulai,
            this.tanggalDaftarUlangSelesai
          );
        if (responseDaftarMahasiswa.status == 200) {
          let daftarMahasiswa = responseDaftarMahasiswa.data.data;

          this.jumlahPendaftar = responseDaftarMahasiswa.data._total;
          this.totalPembayaran = this.$_generalMixin_convertMoney(
            responseDaftarMahasiswa.data.total_pembayaran
          );

          this.detailTable = daftarMahasiswa.map((mahasiswa) => {
            return {
              tanggal: mahasiswa.tanggal_pembayaran,
              no_usm: mahasiswa.nomor_usm,
              nim: mahasiswa.nim,
              nama: mahasiswa.nama,
              dp3: this.$_generalMixin_convertMoney(mahasiswa.biaya_dp3),
              mpt: this.$_generalMixin_convertMoney(mahasiswa.biaya_mpt),
              martikulasi: this.$_generalMixin_convertMoney(
                mahasiswa.biaya_matrikulasi
              ),
              uang_kuliah: this.$_generalMixin_convertMoney(
                mahasiswa.biaya_uang_kuliah
              ),
              potongan: this.$_generalMixin_convertMoney(
                mahasiswa.total_potongan
              ),
              total: this.$_generalMixin_convertMoney(
                mahasiswa.total_harus_dibayar
              ),
            };
          });
        } else {
          let errorMessage = responseDaftarMahasiswa.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
      this.reqTableDone = true;
    },
    onClickExport: function () {
      let token = this.$store.getters["auth/getToken"];

      let url =
        DaftarUlangOldApi.getEndpontExportDaftarUlangHarianByTahunAjaranRangeTanggal(
          this.tahunAjaran,
          this.tanggalDaftarUlangMulai,
          this.tanggalDaftarUlangSelesai,
          token
        );
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx");
      link.click();
    },
  },
};
</script>
<style lang="scss">
.laporan-daftar-ulang-harian {
}
</style>